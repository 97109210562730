@import '/styles/utils/mixins';

.root {
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px 16px;

  &:hover {
    .textContent {
      &__title {
        color: var(--secondary-color);
      }

      &__description {
        color: var(--secondary-color);
        opacity: 0.6;
      }
    }

    .nav__ico {
      fill: var(--secondary-color);
    }

    :global {
      .navIcon_autoposting *,
      .navIcon_account-stats *,
      .navIcon_reports *,
      .navIcon_monitoring *,
      .navIcon_kpi *,
      .navIcon_comparing *,
      .navIcon_bloggersCheck *,
      .navIcon_expressAuditing * {
        stroke: var(--secondary-color);
      }

      .navIcon_kpi rect {
        fill: var(--secondary-color);
      }

      .navIcon_statistika path {
        stroke: var(--secondary-color);
      }
      .navIcon_faq path {
        stroke: var(--secondary-color);
      }
    }
  }
}

.root:hover {
  border-radius: 6px;
  background-color: #f3f4f8;
}

.secondDropdown {
  display: none;
  position: absolute;
  z-index: 1;
  width: 417px;
  right: -417px;
  top: -8px;

  .transparent {
    width: 16px;
  }

  .bodySecondLevel {
    min-width: 385px;
    border-radius: 6px;
    background-color: #ffffff;
    padding: 8px;
    cursor: pointer;
    box-shadow: 0 4px 24px 0 rgba(42, 43, 47, 0.15);
  }
}

.secondDropdown:hover {
  display: flex;
}

.rootSecondLevel:hover .secondDropdown {
  display: flex;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  align-self: flex-start;
}

.textContent {
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-weight: 600;
  color: var(--primary-text-color-lighter);
  margin-left: 12px;

  @include tablet {
    white-space: nowrap;
  }

  &__title {
    line-height: 12px;
    font-size: 12px;
  }

  &__description {
    line-height: 16px;
    font-size: 11px;
    color: var(--secondary-text-color-lighter);
  }
}

.link {
  &::after {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.rightArrow {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  padding-left: 12px;
  z-index: 1;

  @include desktop {
    width: 16px;
    height: 16px;
    z-index: 0;
  }

  .nav__ico {
    transform: rotate(-90deg);
    width: 9px;
    height: 6px;
    fill: var(--secondary-text-color-lighter);
  }

  .nav__icoDawn {
    width: 20px;
    height: 12px;
    transform: rotate(0);
    fill: #b8b9c6;
  }

  .nav__icoUp {
    width: 20px;
    height: 12px;
    transform: rotate(180deg);
    fill: #b8b9c6;
  }
}

.rootSecond {
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px 16px;

  @include desktop {
    padding: 11px 12px;
  }

  .iconSecondLevel {
    margin-left: 36px;
    align-self: flex-start;

    @include desktop {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      margin-left: 0;
    }
  }

  .textContentSecondLevel {
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-weight: 600;
    color: var(--primary-text-color-lighter);
    margin-left: 12px;

    @include tablet {
      white-space: nowrap;
    }

    .titleSecondLevel {
      line-height: 12px;
      font-size: 12px;
    }

    .descriptionSecondLevel {
      line-height: 16px;
      font-size: 11px;
      color: var(--secondary-text-color-lighter);
    }
  }
}

.rootSecond:hover {
  border-radius: 6px;
  background-color: #f3f4f8;

  .textContentSecondLevel {
    .titleSecondLevel {
      color: var(--secondary-color);
    }

    .descriptionSecondLevel {
      color: var(--secondary-color);
      opacity: 0.6;
    }
  }

  :global {
    .navIcon_autoposting-instagram path {
      fill: var(--secondary-color);
    }

    .navIcon_autoposting-odnoklassniki path {
      fill: var(--secondary-color);
    }

    .navIcon_avtoposting-vk path {
      fill: var(--secondary-color);
    }

    .navIcon_avtoposting-v-telegram path {
      fill: var(--secondary-color);
    }

    .navIcon_vk-analytics path {
      fill: var(--secondary-color);
    }

    .navIcon_fb-analytics path {
      fill: var(--secondary-color);
    }

    .navIcon_statistika-akkaunta-tik-tok path {
      fill: var(--secondary-color);
    }

    .navIcon_instagram-analytics path {
      fill: var(--secondary-color);
    }

    .navIcon_statistika-telegram path {
      fill: var(--secondary-color);
    }

    .navIcon_statistika-odnoklassniki path {
      fill: var(--secondary-color);
    }
  }
}

.secondDropdownMobile {
  display: block;

  &_close {
    display: none;
  }
}
