@import 'styles/utils/mixins';

.layout {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal {
  position: relative;
  background: var(--main-white, #fff);

  @include mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: -webkit-fill-available;
    padding: 16px;
    border-radius: 0px;
    overflow: scroll;
  }

  @include tablet {
    margin-top: 100px;
    width: 502px;
    height: fit-content;
    padding: 24px;
    border-radius: 8px;
    overflow: hidden;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include mobile {
    padding: 0px 20px;
  }

  @include tablet {
    padding: 48px 0px;
  }
}

.image {
  img {
    object-fit: cover;
    margin-bottom: 16px;
    width: 132px;
    height: 132px;
  }
}

.closeButton {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: absolute;
  top: 24px;
  right: 24px;
  border: none;
  background: none;
  font-size: 24px;
  color: #a6a9b5;
  font-weight: 600;
  background-color: #f3f4f8;
  cursor: pointer;

  @include mobile {
    display: none;
  }

  @include tablet {
    display: block;
  }
}

.title {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 12px;
  color: var(--main-black, #2a2b2f);
}

.description {
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 18 px;
  margin-bottom: 24px;
  color: var(--gray-1, #a6a9b5);
}

.button {
  height: 56px;

  @include mobile {
    position: absolute;
    bottom: 0;
    width: 92%;
    margin: 16px;
  }

  @include tablet {
    position: relative;
    width: 188px;
    margin: 0px;
  }
}
