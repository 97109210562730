@import '/styles/utils/mixins';
.nav__list {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 32px;
  transition: all 0.4s ease;

  @include responsive-gap {
    gap: 16px;
  }
}

.nav__item {
  position: relative;

  &:hover {
    @include desktop {
      .nav__link {
        color: var(--secondary-color);
      }
    }
  }
}

.nav__item__with_dropdown {
  cursor: pointer;

  .nav__link {
    position: relative;
    padding-right: 16px;
  }
}

.nav__item__open {
  .nav_dropdown {
    display: block;
  }
}

.nav__item_demo {
  @include desktop {
    display: none;
  }
}

.nav__link {
  font-size: 14px;
  font-weight: 600;
  color: var(--primary-text-color);
}

.nav__ico {
  position: absolute;
  width: 9px;
  height: 6px;
  fill: #c8c8ca;
  right: 0;
  top: 8px;
}

.nav_dropdown {
  display: none;
  position: absolute;
  bottom: -16px;
}

.nav_dropdown__list {
  position: absolute;
  top: 0;
  padding: 8px;
  min-width: 350px;
  background-color: #ffffff;
  box-shadow: 0px 4px 24px rgba(42, 43, 47, 0.15);
  border-radius: 6px;
  cursor: default;
}

.nav_dropdown__item {
  display: block;
}
