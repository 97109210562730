@import '/styles/utils/mixins';

.footer {
  background-color: var(--background-color);
  padding: 40px 0 30px 0;

  @include mobile {
    padding: 40px 0 0 0;
  }

  @include tablet {
    padding: 100px 0 0 0;
  }
}

.extraPadding {
  padding-bottom: 350px;

  @media screen and (min-width: 768px) {
    padding-bottom: 210px;
  }

  @media screen and (min-width: 1440px) {
    padding-bottom: 120px;
  }
}

@media screen and (max-width: 767px) {
  .footer__wrapper {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

.footer__wrapper {
  padding-bottom: 32px;
  @include big-desktop {
    width: 1640px;
    margin-left: -40px;
  }
}

.footer__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 2px;
  @include desktop {
    margin-bottom: 32px;
  }
}

.footer__bottom {
  @include desktop {
    display: flex;
  }
}

.footer__container {
  min-width: 224px;
  margin-bottom: 40px;

  @include desktop {
    margin-right: 40px;
  }
}

.footer__section {
  display: flex;
  flex-wrap: wrap;
}

.footer__box {
  min-width: 240px;
  margin-bottom: 40px;
}

.footer__logoWrapper {
  position: relative;
  margin-bottom: 16px;
  width: 140px;
  height: 41px;

  @include desktop {
    margin-bottom: 20px;
  }
}

.footer__logo {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.footerInfo {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.footer__text {
  display: block;
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  color: var(--primary-text-color);

  &_email {
    margin-bottom: 30px;
  }

  &_pr {
    white-space: pre-line;
  }

  &_light {
    line-height: 21px;
    font-weight: 400;
    color: #8f909b;

    @include desktop {
      margin-bottom: 0;
    }
  }

  &_lh {
    line-height: 18px;
  }

  &_cnpj {
    line-height: 21px;
    letter-spacing: -0.356px;
  }

  &_mail {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.contacts {
  margin-bottom: 40px;
}

.footer__link {
  color: var(--primary-text-color);

  &:hover {
    color: var(--secondary-color);
  }
}

.footer__list {
  display: block;
  padding: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.85;
  color: var(--primary-text-color);
}

.footer__social {
  margin-bottom: 40px;

  @include desktop {
    margin-bottom: 0;
    order: 1;
  }
}

.footer__copyright {
  font-size: 14px;
  line-height: 2.28;
  color: var(--primary-text-color);

  @include desktop {
    flex: 1;
  }
}

.footer_list__title {
  display: block;
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: #8f909b;
}

.footer_list__title__link {
  @include desktop {
    &:hover {
      opacity: 0.8;
    }
  }
}

.footer_list__link {
  font-size: 14px;
  line-height: 2.28;
  font-weight: 600;
  color: var(--primary-text-color);
  box-sizing: border-box;

  span {
    color: #a6a9b5;
  }

  @include desktop {
    &:hover {
      color: var(--secondary-color);
    }
  }
}
