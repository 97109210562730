@import 'styles/utils/mixins';

.layout {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal {
  position: relative;
  background: var(--main-white, #fff);

  @include mobile {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    height: -webkit-fill-available;
    padding: 16px;
    border-radius: 0px;
    overflow: scroll;
  }

  @include tablet {
    display: block;
    margin-top: 100px;
    width: 502px;
    height: fit-content;
    padding: 24px;
    border-radius: 8px;
    overflow: hidden;
  }
}

.closeButton {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: absolute;
  top: 24px;
  right: 24px;
  border: none;
  background: none;
  font-size: 24px;
  color: #a6a9b5;
  font-weight: 600;
  background-color: #f3f4f8;
  cursor: pointer;
}

.title {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  margin-bottom: 8px;
  color: var(--main-black, #2a2b2f);

  @include mobile {
    width: 184px;
  }

  @include tablet {
    width: auto;
  }
}

.description {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin-bottom: 24px;
  color: var(--gray-1, #a6a9b5);

  @include mobile {
    width: 303px;
  }

  @include tablet {
    width: 416px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__inputGroup {
    display: flex;
    align-items: center;
    width: -webkit-fill-available;
    padding: 11px 16px;
    border-radius: 8px;
    color: var(--main-black, #2a2b2f);
    border: 1px solid var(--gray-300, #e6e8ee);
  }

  &__input {
    width: -webkit-fill-available;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    border: none;
    padding: 0px;

    &::placeholder {
      color: var(--gray-500, #a6a9b5);
    }

    &:focus {
      outline: none;
    }

    &_error {
      border: 1px solid var(--Error, #fb2424);
    }
  }

  &__error {
    margin-top: -6px;
    text-align: left;
    font-size: 12px;
    font-weight: 400;
    color: var(--Error, #fb2424);
  }

  &__iconSuccess {
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: var(--main-white, #fff);
    background-color: var(--status-success, #02c39a);
  }

  &__iconError {
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: var(--main-white, #fff);
    background-color: var(--status-error, #f90043);
  }

  &__button {
    width: 100%;
    height: 56px;
    margin-top: 12px;
    margin-bottom: 12px;

    @include mobile {
      display: none;
    }

    @include tablet {
      display: block;
    }
  }

  &__buttonMobile {
    width: 100%;
    height: 56px;
    margin-bottom: 16px;

    @include mobile {
      display: block;
    }

    @include tablet {
      display: none;
    }
  }
}

.footerText {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: var(--gray-500, #a6a9b5);

  @include mobile {
    width: 100%;
  }

  @include tablet {
    width: 502px;
  }
}
