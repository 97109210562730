.root {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;

  @media screen and (min-width: 768px) {
    right: 0;
    bottom: -20px;
  }

  svg {
    width: 100%;
    height: 100%;

    @media screen and (min-width: 768px) {
      height: auto;
      min-height: 100%;
    }
  }

  .superLarge {
    display: none;

    @media screen and (min-width: 1920px) {
      display: block;
    }
  }

  .large {
    display: none;

    @media screen and (min-width: 1440px) {
      display: block;
    }

    @media screen and (min-width: 1920px) {
      display: none;
    }
  }

  .desktop {
    display: none;

    @media screen and (min-width: 1024px) {
      display: block;
    }

    @media screen and (min-width: 1440px) {
      display: none;
    }
  }

  .tablet {
    display: none;

    @media screen and (min-width: 768px) {
      display: block;
    }

    @media screen and (min-width: 1024px) {
      display: none;
    }
  }

  .mobile {
    display: block;

    @media screen and (min-width: 768px) {
      display: none;
    }
  }
}