@import '/styles/utils/mixins';

.mobileNav {
  display: none;
  position: fixed;
  top: var(--base-header-height);
  left: 0;
  right: 0;
  bottom: 0;
  padding-bottom: 137px;
  background-color: #ffffff;
  z-index: 100;
  box-sizing: border-box;

  @include tablet {
    padding-bottom: 0px;
    top: var(--base-header-desktop-height);
  }
}

.mobileNav__open {
  display: block;
}

.mobileNav__content {
  padding: 40px 24px 0;
  overflow: hidden;
  overflow-y: auto;
  height: calc(100vh - 137px - var(--base-header-height));
  box-sizing: border-box;
  overscroll-behavior-y: none;
  -webkit-overflow-scrolling: touch;
  height: -webkit-fill-available;
  display: flex;
  flex-direction: column;

  @include tablet {
    height: calc(100vh - 100px - var(--base-header-height));
    padding: 48px 48px 0px;
  }
}

.mobileNav__label {
  margin-bottom: 16px;
  line-height: 27px;
  font-size: 20px;
  font-weight: 600;
  color: #b8b9c6;
}

.mobileNav__actions {
  height: 137px;
  position: fixed;
  display: flex;
  align-items: center;
  bottom: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  margin-bottom: 32px;
  background-color: #ffffff;

  @include mobile {
    padding: 0 48px;
    height: 106px;
  }
  @include tablet {
    display: none;
  }
}

.mobileNav__buttons {
  width: 100%;

  @include tablet {
    display: none;
  }
}
