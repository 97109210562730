.cookies {
  position: fixed;
  right: 24px;
  bottom: 24px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;

  width: 375px;
  padding: 16px;
  border-radius: 8px;

  background-color: var(--main-white);

  box-shadow: 0px 4px 24px 0px rgba(42, 43, 47, 0.15);
  transform: translateY(200px);
  opacity: 0;

  z-index: 1001;
}

.cookies__fade {
  display: flex;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.cookies__is_active {
  transform: translateY(0);
  opacity: 1;
}

.cookies__extraBottom {
  transform: translateY(-360px);

  @media screen and (min-width: 768px) {
    transform: translateY(-200px);
  }

  @media screen and (min-width: 1440px) {
    transform: translateY(-100px);
  }
}

.cookies__closeButton {
  position: fixed;
  right: 16px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.cookies p {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  max-width: 268px;
  color: var(--secondary-text-color-lighter);
}

.cookies p a {
  color: var(--secondary-text-color-lighter);
  text-decoration: underline;
}

.button_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.cookies__applyCookie {
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: var(--secondary-color);
}

.cookies__cancelCookie {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: var(--secondary-text-color-lighter);
}

@media screen and (max-width: 576px) {
  .cookies {
    right: 0px;
    bottom: 0;
    width: 100%;
    min-height: 116px;
    padding: 40px 36px 24px;
    border-radius: 0px;
    align-items: start;
    justify-content: flex-start;
    padding: 16px;
  }

  .cookies__closeButton {
    right: 16px;
    top: 16px;
  }
}
