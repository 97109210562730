@import '/styles/utils/mixins';

.mobileNav__item {
  position: relative;
  padding: 18px 0;
}

.mobileNav__item--open {
  .mobileNav_dropdown {
    display: block;
  }

  .mobileNav__ico {
    transform: rotate(180deg);
  }
}

.mobileNav__item_lang {
  @include tablet {
    display: none;
  }
}

.mobileNav__item_demo {
  display: block;

  @include desktop {
    display: none;
  }
}

.mobileNav__link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 24px;
  font-weight: 600;
  color: var(--primary-text-color);
}

.mobileNav__ico {
  width: 20px;
  height: 12px;
  fill: #B8B9C6;
  transition: transform 0.3s ease;
}

.mobileNav_dropdown {
  display: none;
}

.mobileNav_dropdown__list {
  position: relative;
  margin-top: 16px;
  padding: 8px 0;
  background-color: var(--background-color);
}

.nav__item__with-dropdown {
}

.mobileNav__dropdownLink {
  color: var(--primary-text-color);
  font-size: 14px;
}

.mobileNav__dropdownLink_inactiveLang {
  color: var(--secondary-text-color);
}

.mobileNav__label {
  color: var(--secondary-text-color);
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
}

.mobileNav__button {
  width: 100%;
}
