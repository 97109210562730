@import '/styles/utils/mixins';

.buttons {
  width: 100%;

  @media (min-width: 768px) {
    display: none;
  }
}

.signup {
  margin-bottom: 24px;
  padding: 0 24px;
  width: 100%;
  background: #fee6ec;
  color: var(--primary-color);

  &:hover {
    color: #ffffff;
    background-color: var(--primary-color);
  }
}

.signin {
  display: block;
  width: 100%;
  line-height: 14px;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  color: var(--primary-text-color)
}
