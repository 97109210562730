@import '/styles/utils/mixins';

.social {
  display: flex;
  align-items: center;
  gap: 24px;
}

.social__item {
}

.social__link {
  display: block;
  position: relative;
  width: 24px;
  height: 24px;

  @include desktop {
    &:hover {
      .social__ico {
        fill: var(--secondary-color);
      }
    }
  }
}

.social__ico {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 24px;
  height: 24px;
  fill: #8f909b;
}
