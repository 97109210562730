@import '/styles/utils/mixins';

.languageSwitcher {
  margin: auto -8px 40px;

  @include mobile {
    margin-top: auto;
    margin-bottom: 32px;
  }

  @include tablet {
    margin-top: auto;
    margin-bottom: 0px;
  }
}

.option {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  padding: 0 8px;
  line-height: 20px;
  font-size: 20px;
  font-weight: 600;
  color: #8f909b;
  text-transform: uppercase;

  + .option {
    &:after {
      content: '/';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
      font-size: inherit;
      color: var(--secondary-text-color);
    }
  }
}

.option_current {
  display: inline-block;
  color: var(--primary-text-color);

  &:hover {
    @include tablet {
      color: var(--primary-text-color);
    }
  }
}
