@import '/styles/utils/mixins';

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  background: transparent;

  &_open {
    background: #fff;
  }

  &_scrolled {
    background: #fff;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    padding-left: 16px;
    height: var(--base-header-height);

    @include tablet {
      padding-left: 48px;
      height: var(--base-header-desktop-height);
    }

    @include desktop {
      padding: 0 54px;
    }
  }

  &__logo {
    margin-right: 56px;
  }

  &__menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__nav {
    display: none;

    @include desktop {
      display: block;
    }
  }

  &__actions {
    margin-left: auto;
  }
}

.header__logoImage {
  width: 114px;
  height: 16px;

  @include tablet {
    width: 143px;
    height: 20px;
  }
}
