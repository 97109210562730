.root {
  position: relative;
}

.button {
  position: relative;
  width: 35px;
  line-height: 14px;
  font-size: 14px;
  font-weight: 600;
  color: var(--primary-text-color);
  text-align: left;
  text-transform: uppercase;

  &:hover {
    color: var(--secondary-color);
  }

  &__arrow {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 9px;
    height: 6px;
    fill: #c8c8ca;
  }

  &__icon {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 4px;
  }
}

.dropdown {
  position: absolute;
  bottom: -25px;
  left: -9px;

  &__list {
    position: absolute;
    top: 0;
    padding: 8px 0;
    background-color: #ffffff;
    box-shadow: 0px 4px 24px rgba(42, 43, 47, 0.15);
    border-radius: 6px;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 14px 16px;
    line-height: 12px;
    font-size: 12px;
    font-weight: 600;
    color: var(--primary-text-color-lighter);
    text-transform: uppercase;

    &:hover {
      color: var(--secondary-color);
    }
    &_icon {
      width: 12px;
      height: 12px;
      border-radius: 6px;
      margin-right: 4px;
    }
  }
}
