@import '/styles/utils/mixins';

.section {
  &_marginedTop {
    margin-top: 80px;

    @include tablet {
      margin-top: 100px;
    }

    @include large-tablet {
      margin-top: 130px;
    }
  }

  &_marginedBottom {
    margin-bottom: 80px;

    @include tablet {
      margin-bottom: 100px;
    }

    @include large-tablet {
      margin-bottom: 130px;
    }
  }
}
