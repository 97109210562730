@import '/styles/utils/mixins';

.breadcrumbs {
  margin-bottom: 24px;

  @include mobile {
    display: none;
  }

  @include desktop {
    display: block;
  }
}

.breadcrumbs__list {
  display: flex;
}

.breadcrumbs__item {
  position: relative;
  box-sizing: border-box;
  padding: 0 10px;

  font-size: 14px;
  font-weight: 600;
  line-height: 20px;

  color: var(--primary-text-color);

  &:first-child {
    padding-left: 0px;

    .breadcrumbs__ico {
      display: none;
    }
  }

  &:last-child {
    .breadcrumbs__ico {
      fill: var(--secondary-text-color);
    }
  }
}

.breadcrumbs__link {
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.5);

  &:hover {
    @include desktop {
      color: var(--secondary-color);
    }
  }
}

.breadcrumbs__link__current {
  color: rgba(0, 0, 0, 0.3);
  pointer-events: none;

  &:hover {
    @include desktop {
      color: var(--secondary-text-color);
    }
  }
}

.breadcrumbs__ico {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  left: 0;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.1);
}
