@import '/styles/utils/mixins';

.root {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.item_signup {
  display: none;

  @media (min-width: 768px) {
    display: block;
  }

  @include desktop {
    margin-right: 8px;
  }
}

.item_language {
  display: none;
  margin-left: 24px;

  @include desktop {
    display: block;
  }
}

.item_tablet {
  display: none;

  @include tablet {
    display: block;
  }
}

.item_desktop {
  display: none;

  @include desktop {
    display: block;
  }
}

.item_demo {
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
}

.item__menu {
  @include desktop {
    display: none;
  }
}

.link {
  padding: 0 24px;
  line-height: 14px;
  font-size: 14px;
  font-weight: 600;
  color: var(--primary-text-color);

  &:hover {
    color: var(--secondary-color);
  }

  &_bold {
    font-weight: 700;
  }

  &_separated {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 1px;
      height: 32px;
      background-color: #edf1fe;
    }
  }
}

.menu {
  position: relative;
  width: 60px;
  height: 60px;

  @include tablet {
    width: 99px;
    height: 99px;
  }

  @include desktop {
    display: none;
  }

  &__ico {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 21px;
    height: 2px;
    background-color: var(--primary-text-color);
    border-radius: 2px;

    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 0;
      width: inherit;
      height: inherit;
      background-color: inherit;
      border-radius: inherit;
      transition: 0.3s ease;
    }

    &:before {
      margin-top: -6px;
    }

    &:after {
      margin-top: 6px;
    }
  }

  &_opened &__ico {
    background-color: #ffffff;

    &:before {
      background-color: var(--primary-text-color);
      transform: rotate(-225deg);
      margin-top: 0;
    }

    &:after {
      background-color: var(--primary-text-color);
      transform: rotate(225deg);
      margin-top: 0;
    }
  }
}

.signUp {
  padding: 0 24px;
  background: #fee6ec;
  color: var(--primary-color);

  &:hover {
    color: #ffffff;
    background-color: var(--primary-color);
  }
}

.header__scrolled {
  background-color: #ffffff;
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.04);

  .signUp {
    color: #ffffff;
    background-color: var(--primary-color);

    @include desktop {
      &:hover {
        background-color: var(--primary-color-dark);
      }
    }
  }
}
