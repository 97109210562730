@keyframes show {
  from {
    bottom: -300px;
  }

  to {
    bottom: 0;
  }
}

.root {
  animation: 1s show;
  position: fixed;
  z-index: 10;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px 16px;
  background: var(--main-black);

  @media screen and (min-width: 768px) {
    padding: 16px 24px;
  }

  @media screen and (min-width: 1024px) {
    padding: 16px 48px;
  }

  @media screen and (min-width: 1440px) {
    padding: 16px 24px 16px 198px;
  }

  @media screen and (min-width: 1920px) {
    padding: 16px 48px 16px 222px;
  }

  .content {
    position: relative;

    @media screen and (min-width: 1440px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .title {
    margin-bottom: 12px;
    white-space: pre-line;
    font-family: 'Steppe';
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -0.8px;
    color: var(--main-white);

    @media screen and (min-width: 768px) {
      margin-bottom: 0;
      white-space: nowrap;
      font-size: 32px;
      line-height: 140%;
    }
  }

  .description {
    margin-bottom: 20px;
    font-family: 'Steppe';
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
    color: var(--main-white);

    @media screen and (min-width: 768px) {
      margin-bottom: 24px;
      font-size: 20px;
      line-height: 140%;
    }

    @media screen and (min-width: 1440px) {
      margin-bottom: 0;
    }
  }

  .timer {
    display: flex;
    align-items: flex-start;
    flex-direction: column-reverse;
    row-gap: 30px;

    @media screen and (min-width: 768px) {
      flex-direction: row;
      column-gap: 24px;
    }
  }

  .link {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-radius: 56px;
    width: 100%;
    height: 48px;
    max-width: 343px;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0.28px;
    color: var(--main-black);

    &::after {
      content: '';
      position: absolute;
      transition: transform 0.5s;
      width: 343px;
      height: 343px;
      background-image: linear-gradient(95deg,
          #b6d0f7 0.97%,
          #eef4e1 23.99%,
          #e2c1f9 44.39%,
          #bfd1f9 63.57%,
          #fafcfe 76.02%,
          #bae4e2 81.53%,
          #8c6be3 101.41%);
    }

    &:hover {
      &::after {
        transform: rotate(180deg);
      }
    }

    span {
      position: absolute;
      z-index: 5;
    }

    @media screen and (min-width: 768px) {
      width: 206px;
      height: 58px;

      ::after {
        width: 206px;
        height: 206px;
      }
    }
  }

  .fenekImg {
    display: none;

    @media screen and (min-width: 768px) {
      position: absolute;
      right: -160px;
      bottom: 0;
      display: block;
      width: 300px;
      height: 215px;
    }

    @media screen and (min-width: 1024px) {
      right: 20px;
      height: 230px;
      width: 315px;
    }

    @media screen and (min-width: 1440px) {
      left: 24px;
      right: auto;
      height: 130px;
      width: 180px;
    }

    @media screen and (min-width: 1920px) {
      left: 44px;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .closeButton {
    z-index: 2;
    position: absolute;
    right: 16px;
    top: 24px;
    width: 24px;
    height: 24px;
    cursor: pointer;

    @media screen and (min-width: 768px) {
      display: none;
    }

    svg {
      fill: var(--secondary-text-color-lighter);
    }

    &:hover {
      opacity: 0.8;
    }
  }
}